export const endpoints = {
	widgets: {
		1: "pg_checkbox_blk",
		2: "pg_connect_blk",
		4: "pg_header_blk",
		5: "pg_html_blk",
		6: "pg_image_blk",
		7: "pg_list_blk",
		8: "pg_radio_blk",
		9: "pg_text_blk",
		10: "pg_video_blk",
		11: "pg_compare_blk",
		12: "pg_list_blk",
		13: "pg_text_blk"
	},
	elements: {
		1: "pg_checkbox_blk_answer",
		2: "pg_connect_blk_node",
		7: "pg_list_blk_item",
		8: "pg_radio_blk_answer",
		9: "pg_text_blk_node",
		11: "pg_compare_blk_node",
		12: "pg_list_blk_item",
		13: "pg_text_blk_node"
	},
	additionalHeader: {
		1: null,
		2: {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		},
		6: {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		},
		7: null,
		8: null,
		9: null,
		11: {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		},
		12: null,
		13: null
	}
};
