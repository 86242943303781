import { endpoints } from "./endpoints";

export const widgets = [
	{
		id: 1,
		name: "Multiple Choice Question",
		labelType: "text",
		attribute: "WidgetPopupMultipleQuestion",
		blk_type: 1,
		endpoint: endpoints.widgets[1]
	},
	{
		id: 2,
		name: "True or False",
		labelType: "text",
		attribute: "WidgetPopupTrueFalse",
		blk_type: 8,
		endpoint: endpoints.widgets[8]
	},
	{
		id: 3,
		name: "Comparison",
		labelType: "text",
		attribute: "WidgetPopupComparison",
		blk_type: 11,
		endpoint: endpoints.widgets[11]
	},
	{
		id: 4,
		name: "Connect",
		labelType: "text",
		attribute: "WidgetPopupConnect",
		blk_type: 2,
		endpoint: endpoints.widgets[2]
	},
	{
		id: 5,
		name: "Bullet List",
		labelType: "text",
		attribute: "WidgetPopupBulletList",
		blk_type: 7,
		endpoint: endpoints.widgets[7]
	},
	{
		id: 6,
		name: "Text Block",
		labelType: "para",
		attribute: "WidgetPopupTextBlock",
		blk_type: 9,
		endpoint: endpoints.widgets[9]
	},
	{
		id: 7,
		name: "Headline",
		labelType: "text",
		attribute: "WidgetPopupHeadline",
		blk_type: 4,
		endpoint: endpoints.widgets[4]
	},
	{
		id: 9,
		name: "Image",
		labelType: "image",
		attribute: "WidgetPopupImage",
		blk_type: 6,
		endpoint: endpoints.widgets[6]
	},
	{
		id: 10,
		name: "Video",
		labelType: "video",
		attribute: "WidgetPopupVideo",
		blk_type: 10,
		endpoint: endpoints.widgets[10]
	},
	{
		id: 11,
		name: "IntroList",
		labelType: "text",
		attribute: "WidgetPopupBulletList",
		blk_type: 7,
		endpoint: endpoints.widgets[7],
		intro: true
	},
	{
		id: 12,
		name: "IntroText",
		labelType: "text",
		attribute: "WidgetPopupTextBlock",
		blk_type: 9,
		endpoint: endpoints.widgets[9],
		intro: true
	}
];
