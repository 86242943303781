<template>
	<div class="course-editor-slider-wrapper">
		<!-- <h2 class="course-editor-slider-wrapper__title">Interactive Widgets</h2> -->
		<swiper :slides-per-view="8" :space-between="10" :navigation="true">
			<swiper-slide
				v-for="widget in widgets"
				:key="widget.id"
				class="course-editor-slider-wrapper__slide"
			>
				<figure
					class="course-editor-slider-wrapper__slide--container"
					@click="
						setPopup({
							...widget,
							component: widget.attribute
						})
					"
				>
					<img class="widget-icon-label" :src="require(`@/assets/editor-${widget.labelType}-icon.svg`)"/>
					<h3 class="course-editor-slider-wrapper__slide--title">
						{{ widget.name }}
					</h3>
				</figure>
			</swiper-slide>
		</swiper>
	</div>
</template>

<script>
import { Navigation } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/swiper-bundle.css";
import { widgets } from "@/util/widgets/widgets";

SwiperCore.use([Navigation]);

export default {
	name: "CourseEditorSlider",
	components: {
		Swiper,
		SwiperSlide
	},
	props: {},
	data() {
		return {
			widgets: widgets.filter(el => el.intro !== true)
		};
	},

	methods: {
		setPopup(component) {
			this.$parent.$emit("clicked", true);
			this.$emit("selectWidget", component);
		}
	}
};
</script>

<style lang="scss" scoped>
.widget-icon-label {
    width: 20px;
    height: 20px;
    text-align: center;
    margin: 10px auto;
}

.course-editor-slider-wrapper__slide--container {
	// width: 150px;
    border: 1px solid #2F91AE;
    border-radius: 5px;
	height: 97%;

	h3 {
		font-size: 13px;
	}
}
</style>
